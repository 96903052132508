/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/js-cookie@3.0.5/dist/js.cookie.min.js
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js
 * - /npm/@fortawesome/fontawesome-free@5.13.1/js/all.min.js
 * - /gh/webminds-inc/wm@master/dist/wm.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
